//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_filelist {
  .filelist{
    .icon{
      svg{
        width: 24px;
        height: auto;
      }
    }
    a{
      &:hover{
        background: $blue-dark;
        color: $white;
      }
    }
  }

}

.grid section.flixx_filelist {
  .row > div{

  }
}
