//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_carousel_customers {
  .carousel-inner, .gallery-inner {
    @include maxWidthContainer;
    margin-top: 70px;
    @include media-breakpoint-down(xl) {
      margin-top: 10px;
    }
  }

  .gallery-inner{
    > .row{
      & > div{
        margin-bottom: var(--bs-gutter-x);
      }
    }
  }
}
