//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_carousel_timeline {
  .carousel {
    .carousel-inner {
      @include maxWidthContainer;
      margin-top: 70px;
      @include media-breakpoint-down(xl) {
        margin-top: 10px;
      }

      .carousel-item {
        background: $white !important;

        & > div {

          overflow: hidden;
          border: 0;

          @include media-breakpoint-down(md) {
            border-right: 0 !important;
          }

          .tile {
            & > .row {
              &:first-of-type {
                & > div {
                  h1,
                  .h1,
                  h2,
                  .h2,
                  h3,
                  .h3,
                  h4,
                  .h4,
                  h5,
                  .h5,
                  h6,
                  .h6 {
                    padding-right: 24px;
                    span {
                      color: $blue;
                      margin-top: 15px;
                      margin-bottom: 0px;
                      opacity: 0.2;
                      position: relative;
                      display: inline-block;
                      &:after {
                        background-color: $blue;
                        content: " ";
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 6px;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        opacity: 0;
                      }
                    }


                  }

                  border-bottom: 2px solid $gray-light;
                }
              }

              &:last-of-type {
                & > div {
                  opacity: 0.2;
                  margin-top: 20px;

                  p {
                    padding-right: 24px;
                  }
                }
              }
            }
          }
        }


        &.active {
          & > div {
            &:first-of-type {
              transition: all 0.4s ease-in-out;
              color: $blue-dark;


              .tile {
                & > .row {
                  &:first-of-type {
                    & > div {
                      h1,
                      .h1,
                      h2,
                      .h2,
                      h3,
                      .h3,
                      h4,
                      .h4,
                      h5,
                      .h5,
                      h6,
                      .h6 {
                        span {
                          color: $blue;
                          margin-top: 0px;
                          margin-bottom: 15px;
                          transition: all 0.4s ease-in-out;
                          opacity: 1;
                          &:after{
                            opacity: 1;
                            transition: opacity 0.4s ease-in-out;
                          }
                        }
                      }
                    }

                  }

                  &:last-of-type {
                    & > div {
                      transition: all 0.4s ease-in-out;
                      opacity: 1;

                      p {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
