//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_carousel_testimonials {


  &.bg, .bg {
    &.bg-white {
      * {
        color: $blue-dark;
      }
      .carousel {
        .carousel-item {
          .carousel-caption {

            .person {
              img {
                border: 5px solid $blue-dark;
              }
            }
          }
        }
      }
    }

    &.bg-gray {
      * {
        color: $blue;
      }
      .carousel {
        .carousel-item {
          .carousel-caption {

            .person {
              img {
                border: 5px solid $blue;
              }
            }
          }
        }
        $carousel-control-prev-icon-bg-blue: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$blue}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
        $carousel-control-next-icon-bg-blue: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$blue}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");

        .carousel-control-prev-icon {
          background-image: escape-svg($carousel-control-prev-icon-bg-blue) #{"/*rtl:" + escape-svg($carousel-control-next-icon-bg-blue) + "*/"};
        }
        .carousel-control-next-icon {
          background-image: escape-svg($carousel-control-next-icon-bg-blue) #{"/*rtl:" + escape-svg($carousel-control-prev-icon-bg-blue) + "*/"};
        }
      }
    }

    &.bg-blue {
      * {
        color: $white;
      }
      .carousel {
        .carousel-item {
          .carousel-caption {

            .person {
              img {
                border: 5px solid $white;
              }
            }
          }
        }
      }
    }

    &.bg-ginger {
      * {
        color: $white;
      }
      .carousel {
        .carousel-item {
          .carousel-caption {

            .person {
              img {
                border: 5px solid $white;
              }
            }
          }
        }
      }
    }
  }

  .carousel {
    .carousel-item {
      padding-top: 130px;
      padding-bottom: 130px;

      .carousel-caption {
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        @include maxWidthContainer;
        position: relative;
        text-align: left;

        & > .row {
          margin-top: 50px;
        }

        .person {
          margin-top: 50px;

          img {
            border: 5px solid $blue-dark;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
    }

    button.carousel-control-prev,
    button.carousel-control-next {
      width: calc(100% / 14);
    }
  }

  * {
    color: $white;
  }
}
