//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------
$bottom-padding-for-button: 120px;

section.flixx_download {
  max-width: 812px;
  & > div {

    & > .container {

      position: relative;
      @include maxWidthContainer;

      a{
        height: 100%;
        display: block;
        position: relative;
        padding-bottom: $bottom-padding-for-button;
        @include media-breakpoint-down(xl) {
          padding-bottom: calc($bottom-padding-for-button / 2);
        }

        &:after{
          position: absolute;
          content: ' ';
          display: block;
          right: calc(42px - (var(--bs-gutter-x) / 2));
          height: 132px;
          width: 132px;
          bottom: -42px;
          @include media-breakpoint-down(xl) {
            height: 71px;
            width: 71px;
          }
          background-color: $red;
          background-image: url('//localhost:9090/images//icons/download-white.svg');
          background-repeat: no-repeat;
          background-position: 50% 43%;
          background-size: 40%;
          transition: 0.5s;
        }

        &:hover{
          &:after{
            margin-top: 15px;
            background-position-y: 70%;
          }
        }

        .img-content{
          width: 220px;
          min-height: 310px;
          margin-bottom: 50px;
          border: 1px solid $blue;
        }
      }
    }
    &.bg{
      & > .container {
        padding: calc(var(--bs-gutter-x));

        a{
          padding-bottom: calc($bottom-padding-for-button - var(--bs-gutter-x));
          @include media-breakpoint-down(xl) {
            padding-bottom: calc(($bottom-padding-for-button - var(--bs-gutter-x)) / 2);
          }
          &:after{
            bottom: calc(var(--bs-gutter-x) * -1 - 42px);
            right: calc(42px - (var(--bs-gutter-x)));
          }

          .img-content{
            border: 0;
          }
        }
      }
    }
  }
}

.grid {
  section.flixx_download {
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      margin-bottom: 86px!important;
    }
    & > div {
      & > .container {
        max-width: 100%;
        position: relative;
        padding: 0;
      }
    }
  }
  .bg{
    & > section.flixx_download {
      & > div {
        & > .container {
          a{
            padding-bottom: calc($bottom-padding-for-button - var(--bs-gutter-x));
            @include media-breakpoint-down(xl) {
              padding-bottom: calc(($bottom-padding-for-button - var(--bs-gutter-x)) / 2);
            }
            &:after{
              bottom: calc(var(--bs-gutter-x) * -1 - 42px);
              right: calc(42px - (var(--bs-gutter-x)));
            }

            .img-content{
              border: 0;
            }
          }
        }
      }
    }
  }
}


