//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

@keyframes fade_in_show {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

//all viewports
header {
  position: absolute;
  z-index: 999;
  max-width: 100% !important;
  width: 100% !important;

  .show {
    display: block !important;
  }

  nav.navbar {
    .container-fluid {
      a.navbar-brand {
        max-width: 260px;
        @include media-breakpoint-down(xl) {
          width: 165px;
        }
        > svg{
          width: 100%;
          height: auto;
          path, rect, .st0{
            fill: $blue;
          }
        }

      }
      .navbar-collapse {
        & > ul.navbar-nav {
          & > li.nav-item {
            & > a.nav-link {
              font-weight: 500; // top level only
              a.nav-link span:hover:before{
                display: none!important;
              }
            }
            a.nav-link {
              &.dropdown-toggle {
                &:after {
                  // display: none !important;
                }
              }
            }
            div:first-of-type{
              a span{
                &:before{
                  display: none!important;
                }
              }
            }
            div.submenu-target-l3{
              a{
                color: $blue!important;
                &:hover{
                  //font-weight: bold;
                }
                &:before{
                  display: none!important;
                }
              }
            }
          }
          div:nth-of-type(4){
            h1, h2, h3, h4, h5, h6{
              font-weight: 600;
              font-size: 24px;
              line-height: 35px;
              @include media-breakpoint-down(xl) {
                font-size: 24px;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  header {

    & > .container {
      max-width: $max-width !important;
    }

    nav.navbar {
      padding-top: 118px;
      padding-bottom: 147px;
      position: relative;

      .has-megamenu {
        position: static !important;
      }

      .container-fluid {
        .navbar-brand {
          margin-right: 90px;
          padding: 0;
        }

        .navbar-toggler {
          display: none;
        }

        .navbar-collapse {

          & > ul.navbar-nav {


            //max-width: 1190px;
            max-width: 850px;
            flex: 1 1 auto !important;

            & > li.nav-item {
              flex: 1 1 auto !important;

              & > a.nav-link {
                text-align: right;
              }
              a.nav-link {
                color: $blue-dark;

                &:before {
                  display: none !important;
                }

                &.span {
                  span {
                    &:before {
                      bottom: -14px;
                      height: 6px;
                      background-color: $blue-dark;
                    }
                  }
                }
              }

              &:first-of-type {
                a.nav-link {
                  //text-align: left;
                }
              }

              &:last-of-type {
                a.nav-link {
                  margin-right: 0;
                  padding-right: 0;
                  //text-align: right;
                }
              }

              &.dropdown {
                &:hover .megamenu {
                  display: block;
                  opacity: 1;
                }
              }

              &.active, &:hover {
                & > a {
                  &, span {
                    &:before {
                      transform: scaleX(1);
                    }
                  }
                }
              }

              .megamenu {
                display: none;
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
                left: 0;
                right: 0;
                width: 100%;
                margin-top: 0;

                padding-top: 40px;
                border: 0;
                background: transparent;

                & > .container {
                  margin: 0;
                  max-width: inherit;
                  width: calc(100% + 16px + 2 * 100% / 14);
                  margin-left: calc(100% / 14 * -1 - 8px);
                  padding: 0;
                  border: 0;

                  & > .row {
                    box-shadow: $box-shadow-sm;
                    background: $white;
                    border-top: 2px solid $blue-dark;

                    & > .col {
                      padding-top: 80px;
                      padding-bottom: 80px;

                      & > * {
                        margin-left: 24px;
                        margin-right: 24px;
                      }

                      span {
                        &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
                          color: $blue-dark;
                          margin-bottom: 30px;
                        }
                      }

                      &:nth-of-type(1) {
                        flex: 0 0 20%;

                        span {
                          &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
                            color: $blue-dark;
                          }
                        }
                      }

                      &:nth-of-type(2) {
                        background-color: $gray;
                      }

                      &:nth-of-type(3) {
                        span {
                          &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
                            font-weight: 400;
                            font-size: 29px;
                            line-height: 35px;
                            @include media-breakpoint-down(xl) {
                              font-size: 24px;
                              line-height: 35px;
                            }

                          }
                        }
                      }

                      &:nth-of-type(4) {
                        padding-right: 0;
                        background-color: $blue-dark;

                        span {
                          &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
                            color: $white !important;
                          }
                        }

                        * {
                          color: $white !important;

                          &:before {
                            background-color: $white !important;
                          }
                        }

                        ul {
                          margin-top: 30px;
                        }
                      }


                      a, p {
                        color: $blue-dark;
                        //font-size: 24px;
                        font-size: 1.5rem;
                      }
                    }
                  }
                }

                li.nav-item {
                  a.nav-link {
                    padding-left: 0;
                    padding-right: 0;

                    & + div {
                      display: none!important;
                    }

                  }

                  &.has-submenu {
                    a.nav-link {
                      position: relative;

                      &:after {
                        position: absolute;
                        right: -7px;
                        top: 29%;
                        @include flixx-triangle($blue-dark, 14px);
                      }
                    }
                  }

                  &.active, &:hover {
                    a.nav-link {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }

          div#service {
            display: none;
          }
        }
      }
    }
  }

  body.has-hero {
    header {
      .navbar {
        .container-fluid {
          .navbar-collapse {
            ul.navbar-nav {
              & > li.nav-item {
                & > a.nav-link {
                  color: $white;
                  padding-top: 0;

                  span {
                    color: $white;

                    &:before {
                      background: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// all viewports override backend color
body.has-hero {
  header {
    .navbar {
      .container-fluid {
        .navbar-toggler {
          .navbar-toggler__wrapper {
            cursor: pointer;
            .bar {
              background-color: $white;
            }
          }
        }
      }
    }
  }
}


@include media-breakpoint-only(xl) {
  header {
    .navbar {
      .container-fluid {
        flex-wrap: wrap;

        .navbar-collapse {
          margin-top: 30px;
          flex-basis: 100%;

          ul.navbar-nav {
            max-width: 100% !important;
            & > li.nav-item {
              & > a.nav-link {
                text-align: center!important;

                &:first-of-type {
                  padding-left: 0;
                }
              }
              &:first-of-type {
                a.nav-link {
                  text-align: left!important;;
                }
              }

              &:last-of-type {
                a.nav-link {
                  text-align: right!important;;
                }
              }
            }
          }

          div#service {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  header {
    & > .container, & > .container > .container {
      max-width: 100% !important;
      margin: initial !important;
    }

    .logo-navbar__wrapper {
      @include maxWidthContainer;
    }

    nav.navbar {
      .container-fluid {
        .navbar-brand {
          svg {
            width: 164px;
          }
        }

        $bar-length: 35px;

        .navbar-toggler {
          border: 0;
          padding: 0;
          display: inherit;

          &:focus,
          &:active,
          &-icon:focus {
            outline: none;
            box-shadow: none;
          }

          .navbar-toggler__wrapper {
            margin: 0 auto;
            width: $bar-length;
            height: $bar-length;
            position: relative;

            .bar {
              padding: 0;
              width: 100%;
              height: 15%;
              background-color: $blue-dark;
              display: block;
              border-radius: 0;
              transition: all 0.4s ease-in-out;
              position: absolute;
              transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;

              &.bar1 {
                top: 0;
              }

              &.bar2 {
                width: 1px;
                transform: rotate(90deg);
                left: 50%;
                top: 42%;
              }

              &.bar3 {
                width: 1px;
                left: 50%;
                top: 42%;
              }

              &.bar4 {
                top: 42%;
              }

              &.bar5 {
                bottom: 0px;
              }
            }
          }

          .navbar-toggler__checkbox:checked {
            & + label > .navbar-toggler__wrapper {
              & > .bar1 {
                top: 42.5%;
                background-color: transparent;
              }

              & > .bar2 {
                left: 0px;
                width: $bar-length;
                transform: rotate(45deg);
              }

              & > .bar3 {
                left: 0;
                width: $bar-length;
                transform: rotate(-45deg);
              }

              & > .bar4 {
                background-color: transparent;
              }

              & > .bar5 {
                bottom: 42.5%;
                background-color: transparent;
              }
            }
          }
        }

        .navbar-collapse {
          background: $white;
          overflow-x: hidden;
          margin-top: 12px;
          padding: 10px 0 0;

          ul.navbar-nav {
            @include maxWidthContainer;

            & > li.nav-item {
              a.nav-link {
                &:before, span:before {
                  display: none;
                }
              }

              & > a.nav-link {
                font-size:  24px;
                font-weight: 600;
                //padding: 0;
              }

              &.language {
                display: none;
              }

              &.dropdown {
                & > a.nav-link {
                  position: relative;

                  .toggle-nav{
                    position: absolute!important;
                    width:  30px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: auto;

                    @include flixx-moving-triangle($blue-dark, 15px);

                    &:before {
                      right: 3px;
                    }
                    &:after {
                      top: 19px;
                    }
                  }
                  &.show {
                    .toggle-nav {
                      &:before {
                        right: 13px;
                      }

                      &:after {
                        right: 4px;
                      }
                    }
                  }
                }
              }

              .megamenu {
                border: 0;
                padding: 0;

                &.show{
                  animation: fade_in_show 0.5s;
                }

                & > .container {
                  margin: 0;
                  width: 100%;
                  max-width: 100%;

                  & > .row {
                    & > .col {

                      a, p {
                        font-size: 1.25rem;
                        color: $blue-dark;

                        font-weight: 600;
                      }

                      &:nth-of-type(1) {
                        span {
                          &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
                            display: none;
                          }
                        }
                      }

                      &:nth-of-type(2) {
                        display: none;
                      }

                      &:nth-of-type(3) {
                        display: none;
                      }

                      &:nth-of-type(4) {
                        display: none;
                      }
                    }
                  }
                }

                li.nav-item {
                  a.nav-link {
                    //font-size: 1.25rem;
                    //color: $blue-dark;
                    padding-left: 0;
                    padding-right: 0;


                    & + div {
                      display: none;
                    }

                  }

                  &.has-submenu {
                    & > a.nav-link {
                      .toggle-nav {
                        position: absolute!important;
                        width:  30px;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: auto;
                        @include flixx-moving-triangle($blue-dark, 15px);
                        &:after {
                          top: 19px;
                        }
                        &:before {
                          right: 3px;
                        }
                      }

                      &.show {
                        .toggle-nav {
                          &:before {
                            right: 13px;
                          }

                          &:after {
                            right: 4px;
                          }
                        }
                      }
                    }

                    .dropdown-menu {
                      border: none;
                      background-color: $gray-light;
                      position: relative;

                      &:before, &:after {
                        position: absolute;
                        display: block;
                        content: '';
                        top: 0;
                        bottom: 0;
                        width: 9999px;
                        background-color: $gray-light;
                      }

                      &:before {
                        right: 100%;
                      }

                      &:after {
                        left: 100%;
                      }

                      * {
                        font-weight: 400 !important;
                        //font-size: 20px;
                        font-size: 1.25rem;
                        color: $blue-dark;
                      }

                      &.show{
                        animation: fade_in_show 0.5s;
                      }
                    }
                  }

                  &.active, &:hover {
                    a.nav-link {
                      //font-weight: 600;
                    }
                  }
                }
              }
            }
          }

          div#service {
            @include maxWidthContainer;
            padding: 1px 0 20px;
            background-color: $blue-dark;
            position: relative;

            &:before, &:after {
              position: absolute;
              display: block;
              content: '';
              top: 0;
              bottom: 0;
              width: 9999px;
              background-color: $blue-dark;
            }

            &:before {
              right: 100%;
            }

            &:after {
              left: 100%;
            }

            span {
              &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
              }
            }

            * {
              //font-size: 24px;
              font-size: 1.5rem;
              color: $white !important;

              &:before {
                background-color: $white !important;
              }
            }

            ul {
              margin-top: 20px;

              &:first-of-type {
                margin-text-outline: 0;
              }
            }
          }
        }
      }
    }
  }
}

