//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

.no-bottom-spacing {
  margin-bottom: 0 !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: normal;
  hyphens: auto;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
}


h1, .h1 {
  font-weight: bold;
  //font-size: 125px;
  //line-height: 135px;
  font-size: 95px;
  line-height: 105px;

  @include media-breakpoint-down(xl) {
    font-size: 75px;
    line-height: 85px;
  }
  //color: $blue;

  &.thin {
    font-size: 85px;
    line-height: 95px;
    @include media-breakpoint-down(xl) {
      font-size: 58px;
      line-height: 64px;
    }
  }
}

h2, .h2 {
  font-weight: bold;
  //font-size: 85px;
  //line-height: 95px;
  font-size: 70px;
  line-height: 80px;
  @include media-breakpoint-down(xl) {
    font-size: 48px;
    line-height: 58px;
  }
}

h3, .h3 {
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  @include media-breakpoint-down(xl) {
    font-size: 27px;
    line-height: 37px;
  }
}

h4, .h4 {
  font-weight: 600;
  font-size: 44px;
  line-height: 54px;
  @include media-breakpoint-down(xl) {
    font-size: 27px;
    line-height: 37px;
  }
}

.interferer-top h1,
.interferer-top h2,
h5, .h5 {
  font-weight: bold;
  font-size: 29px;
  line-height: 35px;
  @include media-breakpoint-down(xl) {
    font-size: 24px;
    line-height: 35px;
  }
}

.interferer-top h3,
.interferer-top h4,
.interferer-top h5,
.interferer-top h6,
h6, .h6{
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  @include media-breakpoint-down(xl) {
    font-size: 24px;
    line-height: 35px;
  }
}


.btn {
  font-size: $font-size-base;
  border-radius: 0 !important;

  &.btn-primary {
    /**
        1 $background,
        2 $border,
        3 $color

        4 $hover-background
        5 $hover-border
        6 $hover-color

        7 $active-background
        8 $active-border
        9 $active-color

       10 $disabled-background
       11 $disabled-border
       12 $disabled-color
     */
    @include button-variant(
        $blue,
        $blue,
        $white,
        $white,
        $blue,
        $blue,
        $white,
        $blue,
        $blue,
        $white,
        $blue,
        $blue,
    );

    /**
        1 $padding-y,
        2 $padding-x,
        3 $font-size,
        4 $border-radius
     */
    @include button-size(
      //2rem,
        1.188rem,
        2.45rem,
        1.688rem,
        0
    );
    @include media-breakpoint-down(xl) {
      /**
       1 $padding-y,
       2 $padding-x,
       3 $font-size,
       4 $border-radius
    */
      @include button-size(
        //2rem,
          1.188rem,
          1.688rem,
          1.688rem,
          0
      );
    }
  }

  &.btn-outline-primary {
    /**
        1 $background,
        2 $border,
        3 $color

        4 $hover-background
        5 $hover-border
        6 $hover-color

        7 $active-background
        8 $active-border
        9 $active-color

       10 $disabled-background
       11 $disabled-border
       12 $disabled-color
     */
    @include button-variant(
        $white,
        $blue,
        $blue,
        $blue,
        $blue,
        $white,
        $blue,
        $blue,
        $white,
        $blue,
        $blue,
        $white,
    );

    /**
        1 $padding-y,
        2 $padding-x,
        3 $font-size,
        4 $border-radius
     */
    @include button-size(
      //2rem,
        1.188rem,
        2.45rem,
        1.688rem,
        0
    );
    @include media-breakpoint-down(xl) {
      /**
       1 $padding-y,
       2 $padding-x,
       3 $font-size,
       4 $border-radius
    */
      @include button-size(
        //2rem,
          1.188rem,
          1.688rem,
          1.688rem,
          0
      );
    }
  }

  &.btn-secondary {
    background: none;
    border: none;

    position: relative;
    transition: all 0.5s;

    padding-left: 50px;
    padding-right: 50px;

    &:before {
      content: '';
      position: absolute;
      opacity: 1;
      bottom: 50%;
      left: 2px;
      height: 3px;
      @include media-breakpoint-down(xl) {
        height: 2px;
      }
      width: 38px;
      transition: 0.5s;
      color: $red;
      background-color: $red;
    }

    &:hover:before {
      opacity: 0;
      left: -5px;
    }

    &:after {
      position: absolute;
      opacity: 0;
      right: 0px;
      bottom: 27%;
      @include media-breakpoint-down(xl) {
        bottom: 32%;
      }
      transition: 0.5s;
      color: $red;

      @include flixx-triangle($red);
    }

    &:hover {
      padding-right: 92px;
      padding-left: 8px;
    }

    &:hover:after {
      opacity: 1;
      right: 50px;
    }
  }

  &:focus-visible, &:active {
    box-shadow: none !important;
    background-color: initial;
  }

}

a {
  position: relative;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }

  &:not(.btn, .span), span {
    &:before {
      background-color: $blue;
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: top left;
      transition: transform 0.3s ease;
    }
  }

  &:not(.btn, .span), span {
    position: relative;

    &:hover::before {
      transform: scaleX(1);
    }
  }
}

html {
  //  font-size: 90%;
  font-size: 100%;
  //  @include media-breakpoint-down(xxl) {
  //    font-size: 80%;
  //  }
  //  @include media-breakpoint-down(xl) {
  //    font-size: 70%;
  //  }
  //  @include media-breakpoint-down(sm) {
  //    font-size: 65%;
  //  }
}

body {
  //activate hyphening
  hyphens: auto;

  // sharpening the texts
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  color: $blue-dark;
  font-family: 'Gilroy';

  main, header, footer {
    max-width: 1920px;
    margin: auto;

    & > .container, & > .container .container, & > section > .container {
      @include maxWidthContainer;

      &, .container-fluid {
        padding: 0;
      }
    }
  }

  main {
    & > section {

      &:first-of-type{
        padding-top: 370px;
        @include media-breakpoint-down(xl) {
          padding-top: 135px;
        }
      }

      @include flixx-section-bottom-margin(0px);

      a{
        &:not(.no-icon, .btn, .span){
          &[target="_blank"],
          &[href^='http'] {
            padding-right: 1.25em;
          }
          &[target="_blank"]::after,
          &[href^='http']::after {
            position: absolute;
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            bottom: 3px;
            margin-left: 0.25em;
            background-size: 100%;
            background-image: url("//localhost:9090/images//icons/external-link-blue.svg");
            background-repeat: no-repeat;
          }
        }
      }

      &.bg, .bg {

        &.bg-white {
          background-color: $white;
        }

        &.bg-gray {
          background-color: $gray-super-light;

          .btn {
            &.btn-primary {

              color: $white !important;

              &:hover {
                color: $blue-dark !important;
              }
            }
          }

          a {
            &:not(.btn, .span), span {
              &:before {
                background-color: $blue-dark;
              }
            }
            &:not(.btn, .span){
              &[target="_blank"]::after,
              &[href^='http']::after {
                background-image: url("//localhost:9090/images//icons/external-link-blue-dark.svg");
              }
            }
          }

        }

        &.bg-blue {
          background-color: $blue;

          .btn {
            font-size: $font-size-base;
            border-radius: 0 !important;

            &.btn-primary {
              /**
                  1 $background,
                  2 $border,
                  3 $color

                  4 $hover-background
                  5 $hover-border
                  6 $hover-color

                  7 $active-background
                  8 $active-border
                  9 $active-color

                 10 $disabled-background
                 11 $disabled-border
                 12 $disabled-color
               */
              @include button-variant(
                  $white,
                  $blue-dark,
                  $blue,
                  $blue,
                  $white,
                  $blue-dark,
                  $blue,
                  $blue-dark,
                  $white,
                  $blue,
                  $blue-dark,
                  $white,
              );
              color: $blue-dark !important;

              &:hover {
                color: $white !important;
              }
            }

            &.btn-outline-primary {
              /**
                  1 $background,
                  2 $border,
                  3 $color

                  4 $hover-background
                  5 $hover-border
                  6 $hover-color

                  7 $active-background
                  8 $active-border
                  9 $active-color

                 10 $disabled-background
                 11 $disabled-border
                 12 $disabled-color
               */
              @include button-variant(
                  $blue,
                  $blue,
                  $white,
                  $white,
                  $blue,
                  $blue,
                  $white,
                  $blue,
                  $blue,
                  $white,
                  $blue,
                  $blue,
              );
            }

            &:hover {
              color: $white !important;
            }
          }

          a {
            &:not(.btn, .span), span {
              &:before {
                background-color: $white;
              }
            }
            &:not(.btn, .span){
              &[target="_blank"]::after,
              &[href^='http']::after {
                background-image: url("//localhost:9090/images//icons/external-link-white.svg");
              }
            }
          }
        }

        &.bg-ginger {
          background-color: $ginger;

          .btn {
            font-size: $font-size-base;
            border-radius: 0 !important;

            &.btn-primary {
              /**
                  1 $background,
                  2 $border,
                  3 $color

                  4 $hover-background
                  5 $hover-border
                  6 $hover-color

                  7 $active-background
                  8 $active-border
                  9 $active-color

                 10 $disabled-background
                 11 $disabled-border
                 12 $disabled-color
               */
              @include button-variant(
                  $white,
                  $ginger,
                  $ginger,
                  $ginger,
                  $white,
                  $ginger,
                  $ginger,
                  $ginger,
                  $white,
                  $ginger,
                  $ginger,
                  $white,
              );
              color: $ginger !important;

              &:hover {
                color: $white !important;
              }
            }

            &.btn-outline-primary {
              /**
                  1 $background,
                  2 $border,
                  3 $color

                  4 $hover-background
                  5 $hover-border
                  6 $hover-color

                  7 $active-background
                  8 $active-border
                  9 $active-color

                 10 $disabled-background
                 11 $disabled-border
                 12 $disabled-color
               */
              @include button-variant(
                  $blue,
                  $blue,
                  $white,
                  $white,
                  $blue,
                  $blue,
                  $white,
                  $blue,
                  $blue,
                  $white,
                  $blue,
                  $blue,
              );
            }

            &:hover {
              color: $white !important;
            }
          }

          a {
            &:not(.btn, .span), span {
              &:before {
                background-color: $white;
              }
            }
            &:not(.btn, .span){
              &[target="_blank"]::after,
              &[href^='http']::after {
                background-image: url("//localhost:9090/images//icons/external-link-white.svg");
              }
            }
          }
        }

        * {
          color: $white;
        }
      }
    }
  }
}





