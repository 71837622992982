//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_grid {
  .text-center {
    margin-bottom: 50px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 10px;
    }
  }

  .grid {
    & > .container, & > .container .container, & > section > .container {
      flex-basis: 100%;
      max-width: 100%;
    }

    & > .row {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.5);

      & > div {
        padding-left: 0;
        padding-right: 0;

        & > .row {
          & > div {
            margin-top: var(--bs-gutter-x);

            & > div {
              padding-top: var(--bs-gutter-x);
              padding-bottom: var(--bs-gutter-x);
              @include media-breakpoint-down(lg) {
                //padding-top: 0;
                //padding-bottom: 0;
              }
              //height: 100%; //equal height (Deactivated: because of background color is also equal , see download box)

              &.bg {
                padding-left: var(--bs-gutter-x);
                padding-right: var(--bs-gutter-x);
                //overflow-x: hidden;

                &.bg-blue {
                  background-color: $blue;

                  * {
                    color: $white;
                  }
                }

                &.bg-ginger {
                  background-color: $ginger;

                  * {
                    color: $white;
                  }
                }

                &.bg-gray {
                  background-color: $gray-super-light;

                  * {
                    color: $blue-dark;
                  }
                }


                section {
                  &.flixx_video_image {
                    & {
                      margin-left: calc(var(--bs-gutter-x) * -1);
                      margin-right: calc(var(--bs-gutter-x) * -1);
                    }
                  }
                }
              }

              section {
                margin-bottom: var(--bs-gutter-x);

                .bg {
                  background-color: initial !important;
                }

                &:last-of-type {
                  margin-bottom: 0;
                }

                .container {
                  margin-left: 0;
                  margin-right: 0;
                  padding-left: 0;
                  padding-right: 0;
                }


                &.flixx_video_image {
                  &:first-of-type {
                    //@include media-breakpoint-up(lg) {
                      margin-top: calc(var(--bs-gutter-x) * -1);
                    //}
                  }
                }

                &.flixx_team.person {
                  & > .container {
                    & .row {
                      & > .col-3 {
                        flex-basis: 100% !important;
                      }
                    }
                  }
                }

                &.flixx_video_image {
                  &:last-of-type {
                    @include media-breakpoint-up(lg) {
                      margin-bottom: calc(var(--bs-gutter-x) * -1);
                    }
                  }
                }
              }
            }


          }

          &.row-columns-2 {

          }

          &.row-columns-3 {

          }

          &.row-columns-4 {

          }

          &.row-columns-7-4 {

          }

          &.row-columns-4-7 {

          }
        }
      }
    }
  }
}
