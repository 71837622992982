//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

footer {
  background: $blue-dark;
  padding-top: 110px;
  padding-bottom: 90px;

  *{
    color:  $white!important;
  }
  a{
    &:not(.btn, .span), span{
      &:before {
        background-color: $white;
      }
    }
  }
  span{
    &.h1, &.h2, &.h3, &.h4, &.h5, &.h6{
      margin-bottom: 0;
    }
    &.h1{
      font-size: 110px;
      margin-top: -10px;
      margin-left: -5px;
      @include media-breakpoint-down(xxl) {
        font-size: 100px;
      }
      @include media-breakpoint-down(xl) {
        margin-top: 0px;
        font-size: 67px;
      }
    }
  }

  ul.nav {
    margin-top: 30px;
    @include media-breakpoint-down(xl) {
      margin-top: 15px;
    }

    li.nav-item {
      a.nav-link {
        //font-size: 24px;
        font-size: 1.5rem;
        line-height: 36px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;


      }

      &.active, &:hover{
        a.nav-link.span{
          font-weight: 600;
        }
      }
    }
  }
}
