//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_teaser{
  position: relative;
  & > .filter{
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    display: block;
    & > .background{
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  &.bg{
    &.bg-ginger > .filter{
      .background{
        background-color: $ginger;
        opacity: 0.75;
      }
    }
    &.bg-blue > .filter{
      .background{
        background-color: $blue;
        opacity: 0.75;
      }
    }
    &.bg-gray{
      *{
        color: $blue;
      }
      .btn{
        color: $blue-dark;
      }
    }
  }
  & > .container{
    position: relative;
    z-index: 99;
    & > .row{
      //padding-top: 310px;
      //padding-top: 23.5%;
      padding-top: 15%;
      padding-bottom: 100px;
      & > div{
        display: block;
      }
    }
  }
}
