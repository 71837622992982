//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_team {
  &.list {
    @include flixx-section-bottom-margin(-75px);

    & > .container:first-of-type > .row > div{
      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 46px;
      }
    }

    .grid {
      position: relative;

      .info-box {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        background: $gray-super-light;
        min-height: 200px;
        overflow: hidden;
        box-shadow: $box-shadow-sm;
        z-index: -99;
        margin-top: 10px;
        &.visible{
          z-index: 99;
          opacity: 1;
          margin-top: 0px;
          transition: opacity 0.4s ease-in-out, margin-top 0.4s ease-in-out;
        }

        button {
          position: absolute;
          top: var(--bs-gutter-x);
          right: var(--bs-gutter-x);
          border: 0;
          cursor: pointer;
          padding: 0;
          width: 38px;
          height: 38px;
          background: none;

          span {
            display: block;
            height: 2px;
            width: 100%;
            background-color: $red;
            position: absolute;
            top: calc((38px / 2) - 2px);
            &.bar1{
              transform: rotate(45deg);
            }
            &.bar2{
              transform: rotate(-45deg);

            }
          }
        }

        .details {
          margin: var(--bs-gutter-x);
          height: 100%;
          overflow-x: hidden;
          overflow-y: scroll;
          -ms-overflow-y: scroll;
          -ms-overflow-style: none;
          scrollbar-width: none;

          & > .row {

            & > div {
              &:first-of-type {
                img {
                  width: 90%;
                  height: auto;
                  border-radius: 50%;
                  margin-bottom: 30px;
                }
              }

              &:last-of-type {
                span {
                  display: block;

                  &.position {
                    margin-bottom: 25px;
                  }
                }
              }
            }

          }
        }
      }

      & > .row {
        & > div {
          & > .row {
            & > div {
              margin-top: 0;
              display: flex;
              & > div {
                margin-top: 0;
                padding-top: 0;
                margin-bottom: 50px;
                flex: 1;
              }
            }
          }
        }
      }
    }
  }

  &.person {
    overflow: hidden;
    height: 100%;

    & > .container {
      height: 100%;

      & > .row {
        height: 100%;
        & > div {
          padding-bottom: 30px;
          border-bottom: 2px solid $gray-dark;

          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          img {
            border: 5px solid $white;
            width: 100%;
            height: auto;
            max-width: 180px;
            border-radius: 50%;
            margin-bottom: 30px;
          }

          span {
            display: block;

            &.position {
              margin-bottom: 8px;
            }

            &.h1,
            &.h2,
            &.h3,
            &.h4,
            &.h5,
            &.h6 {
              margin-bottom: 16px;
            }
          }

          & > div.row{
            margin-top: auto;
          }

          .person-details {
            display: none;
          }
        }
      }
    }
  }
}

.grid section.flixx_team {
  .row{
    & > div{
      width: 100%;
      max-width: 544px;
    }
  }
}
