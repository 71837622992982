//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------
$enable-smooth-scroll: false;
$enable-rounded: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-deprecation-messages: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------
//$font-size-base: 1.813rem;
$font-size-base: 1.5rem;
//$line-height-base: 1.813rem;


$blue-light: #9EB8C3;
$blue-dark: #142A48;
$body-color: $blue-dark; // The default text color
$ginger: #D3C4B3;
//$blue: #044A65;
//$blue-light: lighten($blue, 30%);
$blue: #1749C6;
$blue-light: #165EE5;
$blue-super-light: #7AA1FF;


$gray-dark: #707070;
$gray: #EAEAED;
$gray-light: #EAEBF0;
$gray-super-light: #EBEDF2;
$red: #FF6D4C;

$primary: $blue;
$secondary: $gray-light;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: $red;
$light: $gray-light;
$dark: #212529;


$display-font-sizes: (
  //font-size: 125px;
  1: 125rem,
  //font-size: 85px;
  2: 5.313rem,
  //font-size: 44px;
  3: 2.75rem,
  //font-size: 44px;
  4: 2.75rem,
  //font-size: 29px;
  5: 1.813rem,
  //font-size: 29px;
  6: 1.813rem
);


// Forms:
$form-select-bg: $gray-super-light;
$form-label-font-size:                  12px;



//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------
$max-width: 1920px;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: $max-width
);

$section-bottom-margin: 200px;
@mixin flixx-section-bottom-margin($difference: '0px') {
  $_section-bottom-margin: calc($section-bottom-margin + #{$difference});
  margin-bottom: $_section-bottom-margin;
  @include media-breakpoint-down(xl) {
    margin-bottom: calc($_section-bottom-margin * 0.5);
  }
}

@mixin maxWidthContainer() {
  flex-basis: calc(100% / 14 * 12);
  max-width: calc(100% / 14 * 12);
  margin: auto;
}

@mixin flixx-triangle($color, $length:'25px') {
  content: '';
  height: #{$length};
  width: #{$length};
  @include media-breakpoint-down(xl) {
    height: calc(#{$length} * 0.7);
    width: calc(#{$length} * 0.7);
  }
  display: block;
  border-right: 3px solid #{$color};
  border-bottom: 3px solid #{$color};
  @include media-breakpoint-down(xl) {
    border-right: 2px solid #{$color};
    border-bottom: 2px solid #{$color};
  }
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

@mixin flixx-moving-triangle($color, $length:'25px', $top:'19px') {
  position: relative;
  &:before, &:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: #{$length};
    height: 3px;
    background-color: #{$color};
    transition: 0.4s ease;
    top: #{$top};
    bottom: auto;
    left: auto;
    right: auto;
  }
  &:before {
    transform: rotate(45deg);
    right: 0px;

  }
  &:after {
    position: absolute;
    top: calc(#{$top} + 5px);
    right: 12px;
    transform: rotate(135deg);
  }

  &.show, &.show > .toggle-nav {
    &:before {
      right: 8px;
      transform: translate(0px, 0) rotate(45deg);
    }

    &:after {
      right: 3px;
      transform: translate(0px, 0) rotate(135deg);
    }
  }
}

@function toRem($value) {
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}

@function toPx($value) {
  $pxValue: $value + px;
  @return $pxValue;
}
