//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_hero{
  $box-bottom-margin: 80px;
  @include flixx-section-bottom-margin($box-bottom-margin);
  //padding-top: 370px;
  //+ section.flixx_hero{
  //  padding-top: 0px;
  //}
  //@include media-breakpoint-down(xl) {
  //  padding-top: 135px;
  //}
  background-size: cover;
  background-position: center;

  &.image{

    position: relative;

    padding-top: 370px;
    @include media-breakpoint-down(xl) {
      padding-top: 135px;
    }

    span.h4, h1, .h1{
      color: $white!important;
    }

    .bg{
      background-color: $gray;
      padding: 120px 0;
      @include media-breakpoint-down(xl) {
        padding: 50px 0;
      }
      position: relative;
      bottom: calc($box-bottom-margin * -1);
      p, a{
        color: $blue-dark!important;
      }
    }
    &:before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: rgb(23,31,44);
      background: linear-gradient(0deg, rgba(23,31,44,1) 7%, rgba(255,255,255,0) 45%);
    }

    & > .container{

      .row{
        position: relative;
        &:nth-of-type(2){
          margin-top: 244px;
          @include media-breakpoint-down(xl) {
            margin-top: 44px;
          }
          @include media-breakpoint-down(md) {
            margin-top: 0;
          }
        }

        div.bg *{
          color: $blue-dark!important;
        }
      }

      .interferer-top{
        @include media-breakpoint-down(lg) {
          display: none!important;
        }
        position: absolute;
        transform: translate(0, -50%);
        top:  50%;
        right: calc((100% / 14 * -1) - 6px);
        @include background-opacity($gray, 0.9);
        padding: 90px 80px 70px;

        @include media-breakpoint-down(xl) {
          padding: 50px 70px 40px;
        }

        max-width: 540px;
        margin-top: -10px;
        z-index: 9999;

        button {
          position: absolute;
          top: var(--bs-gutter-x);
          right: var(--bs-gutter-x);
          border: 0;
          cursor: pointer;
          padding: 0;
          width: 38px;
          height: 38px;
          background: none;

          span {
            display: block;
            height: 2px;
            width: 100%;
            background-color: $red;
            position: absolute;
            top: calc((38px / 2) - 2px);
            &.bar1{
              transform: rotate(45deg);
            }
            &.bar2{
              transform: rotate(-45deg);

            }
          }
        }
      }
      .interferer-bottom{
        @include media-breakpoint-up(lg) {
          display: none!important;
        }
      }
    }
  }

  &:not(.image){
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
      margin-bottom: 46px;
    }

    span{
      &.h1,
      &.h2,
      &.h3,
      &.h4,
      &.h5,
      &.h6{
        margin-bottom: auto;
      }
    }
  }
}
