//---------------------------------------------
//
//  Fonts
//
//    1. Loading Mixins
//    2. Loading Fonts
//
//---------------------------------------------

//---------------------------------------------
// 1. Loading Mixins
//---------------------------------------------

@use "mixins/google-fonts";

//---------------------------------------------
// 2. Loading Fonts
//---------------------------------------------
.form-control {
  background-color: $gray-super-light;
}

.form-floating {
  > label {
    //color: $form-text-color;
    top: 3px;
  }

  > .form-control,
  > .form-control-plaintext {
    padding: $form-floating-padding-y $form-floating-padding-x;
    min-height: 70px;

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }



  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      font-size: 14px;

      &::after {
        background-color: transparent;
      }
    }
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown) {
    padding-top: 40px !important;
    padding-bottom: 15px !important;
  }

  > textarea.form-control:focus,
  > textarea.form-control:not(:placeholder-shown) {
    min-height: 200px;
    padding-top: 28px !important;
    padding-bottom: 15px !important;
  }

  > .form-select:focus,
  > .form-select:not(:placeholder-shown) {
    padding-top: 28px !important;
    min-height: 70px;
  }

}
