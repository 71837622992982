//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_carousel_bankasaservice {
  & > .container {
    padding: calc(var(--bs-gutter-x));

    * {
      color: $blue-dark;
    }

    & > h1,
    & > .h1,
    & > h2,
    & > .h2,
    & > h3,
    & > .h3{
      //color: $blue;

      margin-top: 30px;
      @include media-breakpoint-down(xl) {
        margin-top: 10px;
      }
    }
    & > p {
      margin-top: 30px;
      margin-bottom: 60px;
      @include media-breakpoint-down(xl) {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }


    & > .row {
      &:first-of-type {
        & > div.col-12 {
          &:first-of-type {
            & > .row {
              //margin: 0;
            }
          }
        }
      }

      &:last-of-type {
        & > div.col-12 > div.col-12 {
          margin-bottom: 0!important;
        }
      }
    }

    .carousel {
      button {
        &.carousel-control-prev {
          //left: -80px;
          left: -9%;
          @include media-breakpoint-down(sm) {
            left: -10%;
          }
        }

        &.carousel-control-next {
          //right: -80px;
          right: -9%;
          @include media-breakpoint-down(sm) {
            right: -10%;
          }
        }
      }

      &.items-2-1 {
        .carousel-inner {
          .carousel-item {
            & > div.tile {
              overflow: hidden;
              border: 0;

              &:first-of-type {
                border-right: calc(var(--bs-gutter-x)) solid $gray-super-light;
              }

              @include media-breakpoint-down(md) {
                border-right: 0!important;
              }
            }
          }
        }
      }
    }

    div.tile {
      margin-bottom: calc(var(--bs-gutter-x));

      & > a.span {
        position: relative;
        padding: calc(var(--bs-gutter-x));
        display: block;
        height: 100%;

        h3 {
          color: $blue;
          margin-bottom: 30px;
          padding-right: 30px;
          max-width: 90%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }

        p {
          padding-right: 30px;
          font-weight: 500;
          max-width: 90%;
          @include media-breakpoint-down(md) {
            max-width: 100%;
          }
        }

        cursor: pointer;

        &:after {
          @include flixx-triangle($blue);
          position: absolute;
          right: calc(var(--bs-gutter-x) + 6px);
          bottom: calc(var(--bs-gutter-x));
          top: auto;
          left: auto;
          cursor: pointer;
          transition: 0.5s;

        }

        &:hover {
          &:after {
            margin-right: -5px;
            border-color: $red;
          }
        }
      }
    }
  }
}
