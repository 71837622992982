//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

.carousel {
  &.side-by-side, &.items-4-3-2-1, &.items-3-2-1, &.items-2-1, &.side-by-side.items-4-3-2-1, &.side-by-side.items-3-2-1, &.side-by-side.items-2-1 {
    .carousel-inner {
      .carousel-item {

        &.active,
        &-next,
        &-prev {
          display: flex;
        }

        &-end,
        &-start {
          transform: translateX(0);
        }

        //&-end.active,
        //&-next {
        //  transform: translateX(100%);
        //}

        & > a:not(.btn) {
          display: block;

          img {
            width: 100%;
          }
        }

        @include media-breakpoint-down(md) {
          & > .tile {
            display: none;

            &:first-of-type {
              display: block;
            }
          }
        }

        .tile{
          background: $white;
        }
      }
    }

    button.carousel-control-prev,
    button.carousel-control-next {
      width: calc(100% / 14);
    }
  }

  &.items-4-3-2-1 {
    .carousel-inner {
      .carousel-item {
        &-end.active,
        &-next {
          transform: translateX(100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(25%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(33.333%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(50%);
          }
        }

        &-start.active,
        &-prev {
          transform: translateX(-100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(-25%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(-33.333%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(-50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  &.items-3-2-1 {
    .carousel-inner {
      .carousel-item {
        &-end.active,
        &-next {
          transform: translateX(100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(33.333%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(33.333%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(50%);
          }
        }

        &-start.active,
        &-prev {
          transform: translateX(-100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(-33.333%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(-33.333%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(-50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  &.items-2-1 {
    .carousel-inner {
      .carousel-item {
        &-end.active,
        &-next {
          transform: translateX(100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(50%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(50%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(100%);
          }
        }

        &-start.active,
        &-prev {
          transform: translateX(-100%);
          @include media-breakpoint-up(xl) {
            transform: translateX(-50%);
          }
          @include media-breakpoint-only(lg) {
            transform: translateX(-50%);
          }
          @include media-breakpoint-only(md) {
            transform: translateX(-50%);
          }
          @include media-breakpoint-only(sm) {
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}


