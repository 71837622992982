//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------
section.flixx_search {

  & + section.flixx_search{
    margin-top: -100px;
  }

  form{
    button.btn-primary{
      width: 100%;
    }

    .form-floating > .form-control {
      min-height: 76px;
    }
  }

  .results{
    span.count{
      color: $gray-dark;
    }
    .hit{
      font-weight: 600;
    }
    li{
      border: 0!important;
      margin: 30px 0;
      padding-left: 0;
    }
  }

  ul.pagination{
    li.page-item{

      a.page-link{
        &:focus{
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }

      a.page-link{
        color: $gray-dark;
        &.prev{
          border-left: 0;
          border-top: 0;
          border-bottom: 0;
        }
        &.next{
          border-right: 0;
          border-top: 0;
          border-bottom: 0;
        }
      }
      span.page-link{
        background-color: $blue-dark;
      }
    }
  }
}



