//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_counter{

  .row{
    &:first-of-type{
      margin-bottom: 70px;
      @include media-breakpoint-down(xl) {
        margin-bottom: 20px;
      }
    }
    &:last-of-type{
      div {
        text-align: center;
        @include media-breakpoint-down(xl) {
          margin-bottom: 50px;
        }
        span{
          display: block;
          &.label{
            color: $blue-dark;
          }
          &.counter{
            background: none;
            font-size: 6rem;
            color: $blue-dark;
            font-weight: bold;
          }
        }
      }
    }
  }

}
