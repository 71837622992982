//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_video_image {
  & > div {
    & > .container {
      position: relative;
      @include maxWidthContainer;

      & > .ratio, & > img {
        bottom: -85px;
        @include media-breakpoint-down(xl) {
          bottom: -35px;
        }
        position: relative;
      }
    }
  }
}

.grid {
  section.flixx_video_image {
    & > div {
      & > .container {
        max-width: 100%;
        position: relative;
        padding: 0;

        & > .ratio, & > img {
          bottom: 0;
        }
      }
    }
  }
}
