//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";
@import "basics";
@import "forms";

//---------------------------------------------
// 2.Components
//---------------------------------------------

@import "components/header";
@import "components/footer";
@import "components/carousel";

//Elements
@import "components/flixx_grid";
@import "components/flixx_hero";
@import "components/flixx_carousel_customers";
@import "components/flixx_carousel_testimonials";
@import "components/flixx_carousel_bankasaservice";
@import "components/flixx_carousel_timeline";
@import "components/flixx_carousel_news";
@import "components/flixx_counter";
@import "components/flixx_teaser";
@import "components/flixx_team";
@import "components/flixx_form";
@import "components/flixx_video_image";
@import "components/flixx_download";
@import "components/flixx_filelist";
@import "components/flixx_search";

//.row [class*='col-'] {
//  background-color: #ffeeee;
//  background-clip: content-box;
//  min-height: 20px;
//}

#mq-detector{
  position: fixed;
}
