//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------
section.flixx_form {
  form{
    > .row + .row.form-static-text{
      margin-top: 20px;
    }
  }
}

.grid {
  section.flixx_form {
    .row > div.col-12.col-lg-5{
      width: 100% !important;
    }
  }
}
