//---------------------------------------------
//
//  Component
//
//    1. Imports
//    2. Code
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------
@use "../variables";

//---------------------------------------------
// 2. Code
//---------------------------------------------

section.flixx_news,
section.flixx_carousel_news {
  & > .container {
    margin-bottom: calc(var(--bs-gutter-x));

    &:last-of-type {
      margin-bottom: 0;
    }

    &.topnews, &.filter, &.more {
    }

    img {
      margin-bottom: 30px;
    }

    &.filter{
      margin-top: 170px;
      margin-bottom: 65px;

      @include media-breakpoint-down(xl) {
        margin-top: 85px;
        margin-bottom: 30px;
      }

      ul{
        margin-bottom: 0;
        li{

          border: 0;
          padding: calc(var(--bs-gutter-x));
          padding-left: 0;
          padding-top: 0;
          &:first-of-type{
            font-weight: bold;
            width: 160px;
            @include media-breakpoint-down(xl) {
              width: 120px;
            }
          }
          &.active{
            a{
              font-weight: bold;
              &:before{
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }

    .carousel {
      button {
        &.carousel-control-prev {
          //left: -80px;
          left: -9%;
          @include media-breakpoint-down(xl) {
            left: -7%;
          }
          @include media-breakpoint-down(sm) {
            left: -5%;
          }
        }

        &.carousel-control-next {
          //right: -80px;
          right: -7%;
          @include media-breakpoint-down(xl) {
            right: -7%;
          }
          @include media-breakpoint-down(sm) {
            right: -5%;
          }
        }
      }

      &.items-2-1 {
        .carousel-inner {
          .carousel-item {
            background-color: $white;
            & > div.tile {
              overflow: hidden;
              border: 0;

              img{
                border-right: calc(var(--bs-gutter-x)) solid $white;
              }

              &:first-of-type {
                border-right: calc(var(--bs-gutter-x)) solid $white;
                img{
                  border-right: 0;
                }
              }


              @include media-breakpoint-down(md) {
                border-right: 0 !important;
                img{
                  border-right: 0;
                }
              }
            }
          }
        }
      }
    }

    div.tile {
      position: relative;
      margin-bottom: calc(var(--bs-gutter-x));
      padding-bottom: 50px;

      a.btn.btn-secondary{
        position: absolute!important;
        @include media-breakpoint-down(md) {
          position: relative!important;
        }
      }
    }
  }
}

section.flixx_hero + section.flixx_news{
  margin-top: calc($section-bottom-margin / 2 * -1)
}
